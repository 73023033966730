
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-3">
        <div class="input-group mb-3 pull-right">

          <input type="text" v-model="search_keyword" class="form-control" placeholder="Search outlet" aria-label="" aria-describedby="basic-addon1">

          <div class="input-group-append">
            <button @click="searchOutlets" class="btn btn-primary" type="button">Search</button>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <a href="/apps/importOutlets" class="btn btn-success float-right ml-5" >Import outlets</a>
        <a href="/apps/createOutlet" class="btn btn-danger float-right" >Add new</a>
      </div>
    </div>

    <br/>

    <b-table striped hover :items="outlets" :fields="columns">


      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template v-slot:cell(name)="data">
        <a style="text-decoration: underline;font-weight: bolder;" :href="'/apps/outletProfile/'+data.item.id">{{ data.value }}</a>
      </template>
      <template v-slot:cell(logo)="data">
          <img style="width: 100px;height: 50px;" :src="data.value" class="img img-thumbnail img-responsive" />
      </template>
      <template v-slot:cell(category)="data">
        <span>{{ data.value.name }}</span>
      </template>

      <template v-slot:cell(outletUsers)="data">

        <ul>
          <li v-for="outletUser in data.value"> {{  outletUser.user.name }} </li>
        </ul>

      </template>


      <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">
                   {{ data.value }}
                </span>
        <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
      </template>
      <template v-slot:cell(options)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-dropdown text="options">
          <b-dropdown-item :href="'/apps/editOutlet/'+data.item.id" >Edit</b-dropdown-item>
          <b-dropdown-item href="#" v-if="data.item.status == 'ACTIVE'" @click="openUpdateOutletStatus('SUSPENDED', data.item)"  >Deactivate</b-dropdown-item>
          <b-dropdown-item href="#" v-else @click="openUpdateOutletStatus('ACTIVE', data.item)"  >Activate</b-dropdown-item>
          <!--<b-dropdown-item href="#" @click="openDeleteOutlet(data.item)">Delete</b-dropdown-item>-->
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["No","logo","name","phoneNumber","email","openingTime","closingTime","category","floorLevel","status","loyaltyStatus","outletUsers","options"],
      outlets:[],
      users:[],
      floor_levels:[0,1,2,3,4],
      outlet:{
        id:0,
        name:"",
        phoneNumber:"",
        email:"",
        website:"",
        facebook:"",
        twitter:"",
        instagram:"",
        youtube:"",
        description:"",
        shopNo:"",
        openingTime:"",
        closingTime:"",
        logo:"",
        banner:"",
        category:"",
        floorLevel:"",
        loyalty_points:"",
        redemption_value:"",
      },
      categories:[],
      logo:"",
      banner:"",
      status:"",
      search_keyword:""
    };
  },
  components: {
    Loading,
    DatePicker
  },
  mounted (){
    this.getCategoriesData();
    this.getOutletData();
  },
  methods: {
    ...mapActions(["getCategories","addOutlet","getOutlets", "deleteOutlet","updateOutletStatus","searchOutletsByName"]),

    searchOutlets(){
      let self = this;

      this.isLoading = true;

      this.searchOutletsByName({name: this.search_keyword})
        .then(function (outlets) {
          self.isLoading = false;

          self.outlets = outlets;
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

    openUpdateOutletStatus(status, outlet){



      this.status = status;
      this.outlet = outlet;

      let self = this;
      this.$bvModal.msgBoxConfirm('Confirm outlet status update ?')
        .then(value => {

          if(value){
            self.updateOutletStatusData();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    updateOutletStatusData(){
      let self = this;

      this.isLoading = true;

      this.updateOutletStatus({status: this.status, outletId: this.outlet.id })
        .then(function () {
          self.isLoading = false;

          self.$bvToast.toast("Outlet details updated successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getOutletData();
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

    handleLogoImageUpload(){
      this.logo = this.$refs.logo.files[0];
    },
    handleBannerImageUpload(){
      this.banner = this.$refs.banner.files[0];
    },
    getCategoriesData(){
      let self = this;
      this.getCategories()
        .then(function (categories) {
          self.categories = categories;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    confirmDeleteOutlet(){
      this.isLoading = true;

      let self = this;


      this.deleteOutlet({outlet_id: this.outlet.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Outlet deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getOutletData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    openDeleteOutlet(outlet){

      this.outlet = outlet;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.outlet.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteOutlet();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    getOutletData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {
          self.outlets = outlets;
        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    }

  }
};
</script>
